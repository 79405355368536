import React, { Component } from 'react'
import Header from './Header'
import Footer from './Footer'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core"

import "normalize.css"
import "../styles/index.scss"

library.add(
    fab
)

export default class Container extends Component {
    render() {
        return (
            <div className={`o-container o-container--${this.props.id}`}>
                <Header page={this.props.id}/>
                <div className="o-main">
                    {this.props.children}
                </div>
                <Footer />
            </div>
        )
    }
}