import React, { Component } from 'react'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Footer extends Component {
  render() {
    return (
      <div className="o-footer">
        {/* <ul className="m-footer-link__wrapper">
          <Link className="m-footer-link" to="/contact">About</Link>
          <Link className="m-footer-link" to="/blogposts">Blog</Link>
          <Link className="m-footer-link" to="/contact">Contact</Link>
         </ul> */}
        <span>Where you can find me</span>
        <div className="m-social-links">
        </div>
      </div>
    )
  }
}
