import React from 'react'
import { Link, StaticQuery, graphql } from "gatsby";
import Logo from "../images/rob_logo.svg";

export default (props) => (
    // StaticQuery is for extracting data at a component level -- Source: https://www.gatsbyjs.org/docs/static-query/

    <StaticQuery
        query={graphql`
            query headerQuery {
                site {
                    siteMetadata {
                        menuLinks {
                            name
                            link
                        }
                    }
                }
            }
        `}
        render={data => (
            <div className="o-header">
                <Link className="a-logo-link" to="/" aria-label="Homepage">
                    <Logo />
                </Link>
            </div>
        )}
    />

)